import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Input, Typography, DatePicker, Button, Spin } from "antd";
import { HighlightOutlined, BankOutlined, WhatsAppOutlined, AppleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployee } from "../../state/action-creators";
import aadhaar from "../../assets/icons/aadhaar.svg"
import { PostalCodeApi, employeeSave, triggerAdharconsentBot, triggerKYC_bot } from "../../utitlities/request";
import {
  Personal,
  Employment,
  Salary,
  BankDetails,
  Data,
  DocumentSchema,
  MainReducer,
  ExistingLoanRequest,
  ExistingLoan,
  BankStatementData,
} from "../../types";
import moment from "moment";
import { toast } from "react-toastify";
import "../../styles/buttons.css";
import { request } from "http";
const { Text } = Typography;

interface AddressItem {
  address: string;
  type: string;
}

const EmployeeInformation: React.FC = (props: any) => {
  // props=props.props;
  const dispatch = useDispatch();
  const employee = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
  });
  const loanRequest = useSelector((state: MainReducer) => {
    return state.loan.loanRequest.body;
  }) || {};

  const [loadingKYC, setLoadingKYC] = useState(false);
  const [loadingConsent, setLoadingConsent] = useState(false);

  const triggerKYC = async () => {
    setLoadingKYC(true);
    try {
      const whatsapp_number = employee?.whatsapp_no?.trim();

      if (!whatsapp_number || whatsapp_number.length < 10) {
        throw new Error('Invalid mobile number: Mobile number must be at least 10 digits.');
      }

      const params = {
        mobile_no: whatsapp_number.length === 10 ? "91" + whatsapp_number : whatsapp_number
      };
      const response = await triggerKYC_bot(params);
      if (response?.data == "OK") {
        toast.success('KYC Bot triggered Successfully', {
          position: toast.POSITION.TOP_CENTER
        })
      }
    } catch (error) {
      toast.error('Error occured in triggering KYC bot', {
        position: toast.POSITION.TOP_CENTER
      })
      console.error('Error details:', error);
    }
    finally {
      setLoadingKYC(false);
    }

  }

  const triggerAdharconsent = async () => {
    setLoadingConsent(true);
    try {
      const params = {
        request_id:loanRequest.id,emp_id:employee.id,request_type:"consent",source:"ops_dashboard"
      };
      const response = await triggerAdharconsentBot(params);
      if (response?.success) {
        toast.success('Consent link Generated Successfully', {
          position: toast.POSITION.TOP_CENTER
        })
      }
    } catch (error) {
      toast.error('Error occured in Consent link Generation', {
        position: toast.POSITION.TOP_CENTER
      })
      console.error('Error details:', error);
    }
    finally {
      setLoadingConsent(false);
    }

  }




  employee.other_address = employee.other_address || [];
  return (
    <div className="emp-info-form">
      <div className="title">Employee Information</div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      <div className="form01">
        <div>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">First Name</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      first_name: val,
                    }))
                  },
                }}
              >
                {employee?.first_name}
              </Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Last Name</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      last_name: val,
                    }))
                  },
                }}
              >
                {employee?.last_name}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Mobile</div>
              <Text style={{ fontSize: "14px" }}>
                {employee?.mobile}
              </Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Email</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      email_id: val,
                    }))
                  },
                }}
              >
                {employee?.email_id}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">DOB</div>
              <DatePicker
                format="DD-MM-YYYY"
                value={employee?.dob ? moment(employee.dob, "YYYY-MM-DD") : null}
                onChange={(date, dateString) => {
                  if (dateString) {
                    let formattedDate = moment(dateString, "DD-MM-YYYY").format('YYYY-MM-DD') || null
                    dispatch(updateEmployee({
                      ...employee,
                      dob: formattedDate,
                    }))
                  }
                }}
              />
            </Col>
            <Col xs={24} xl={8} className="info-input-row">
              <div className="label">Father Name</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      father_name: val,
                    }))
                  },
                }}
              >
                {employee?.father_name}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Gender</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      gender: val,
                    }))
                  },
                }}
              >
                {employee?.gender}
              </Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Marital Status</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      marital_status: val,
                    }))
                  },
                }}
              >
                {employee?.marital_status}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">PAN Number</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      pan_no: val,
                    }))
                  },
                }}
              >
                {employee?.pan_no}
              </Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Aadhar</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      aadhaar: val,
                    }))
                  },
                }}
              >
                {employee?.aadhaar}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">WhatsApp Number</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      whatsapp_no: val,
                    }))
                  },
                }}
              >
                {employee?.whatsapp_no}
              </Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Temporary Contact Number</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      mobile_2: val,
                    }))
                  },
                }}
              >
                {employee?.mobile_2}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Employee Operations ID</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      ops_id: val,
                    }))
                  },
                }}
              >
                {employee?.ops_id}
              </Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Employee ID (With Domain)</div>
              <Text style={{ fontSize: "14px" }}>{loanRequest["client_employee_id"] || '-'}</Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Employee ID (Without Domain)</div>
              <Text style={{ fontSize: "14px" }}>{loanRequest && loanRequest["client_employee_id"] ? loanRequest["client_employee_id"].split("$")[0] : ''}
              </Text>
            </Col>
            <Col>
              <Button
                icon={<WhatsAppOutlined />}
                type="primary"
                className="antd-button"
                style={{
                  backgroundColor: "#1B875A",
                  border: 0
                }}
                onClick={triggerKYC}
                loading={loadingKYC}
              >
                Trigger KYC Bot
              </Button>
              <Button
                 icon={
                  <img
                    src={aadhaar} // Replace with your PNG URL
                    alt="Custom Icon"
                    style={{ width: 25, height: 25 ,marginRight:"5px" }} // Adjust size as needed
                  />
                }
                type="primary"
                className="antd-button"
                style={{
                  backgroundColor: "#1B875A",
                  border: 0,
                  marginLeft :"10px"
                }}
                onClick={triggerAdharconsent}
                loading={loadingConsent}
              >
                Trigger Digilocker
              </Button>
            </Col>
          </Row>
        </div>
        <div className="detail-blocks">
          <div className="block-title">Permanent Address</div>
          <Row gutter={24}>
            <Col xs={24} xl={24} className="input-row">
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      address: val,
                    }))
                  },
                }}
              >
                {employee?.address}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">State</div>
              <Text style={{ fontSize: "14px" }}>{employee.state}</Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">District</div>
              <Text style={{ fontSize: "14px" }}>{employee.city}</Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Pin Code</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: async (val) => {
                    const { district, state } = await PostalCodeApi(val);
                    dispatch(updateEmployee({
                      ...employee,
                      city: district,
                      state: state,
                      pincode: val,
                    }))
                  },
                }}
              >
                {employee?.pincode}
              </Text>
            </Col>
          </Row>
        </div>
        <div className="detail-blocks">
          <div className="block-title">Temporary Address</div>
          <Row gutter={24}>
            <Col xs={24} xl={24} className="input-row">
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      address_1: val,
                    }))
                  },
                }}
              >
                {employee?.address_1}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">State</div>
              <Text style={{ fontSize: "14px" }}>{employee.state_1}</Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">District</div>
              <Text style={{ fontSize: "14px" }}>{employee.city_1}</Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Pin Code</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: async (val) => {
                    const { district, state } = await PostalCodeApi(val);
                    dispatch(updateEmployee({
                      ...employee,
                      city_1: district,
                      state_1: state,
                      pincode_1: val,
                    }))
                  },
                }}
              >
                {employee?.pincode_1}
              </Text>
            </Col>
          </Row>
        </div>
        <div className="detail-blocks add-per-doc-block">
          <div className="block-title">Address as per documents</div>
          <Row gutter={24} className="add-per-doc-header">
            <Col xs={16} xl={16} className="col-divide">
              Address
            </Col>
            <Col xs={8} xl={8} className="">
              Document Type
            </Col>
          </Row>
          {employee.other_address.map((addressItem: AddressItem, index: number) => (
            <Row gutter={24} className="add-per-doc-row">
              <Col xs={16} xl={16} className="col-divide">
                {addressItem.address}
              </Col>
              <Col xs={8} xl={8} className="">
                {addressItem.type}
              </Col>
            </Row>
          ))}
        </div>
        <div>

        </div>
      </div>
    </div>
  );
};
export default EmployeeInformation;
