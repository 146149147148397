// By default the status of a Loan Request is ORIGINATED

export const OriginatedStatus: any = [
    'Pre Check Complete',
    'CA Rejected - eNACH Not Possible',
    // 'CA Rejected - Less Than 3 Months Exp',
    'CA Rejected - Less Than Min Exp',
    'CA Rejected - Salary Unavailable For 3 Months',
    'CA Rejected - No Salary Data',
    'CA Rejected - Old Salary Data',
    'CA_rejected - existing loan',
    'Rejected - Duplicate'
]

export const OriginatedStatusDisplay: any = [
    'ORIGINATED',
]

// OPS_APPROVED TO Appraisal

export const OperationsStatus: any = [
    'Appraisal',
    'Appraisal2',
    'KYC Complete',
    'Digilocker KYC Incomplete',
    'Digilocker KYC Complete',
    'Ops Rejected',
    'Ops Documents Pending',
    'Ops Not Interested',
    'Rejected - Duplicate',
    'Cold Lead',
    'No Response',
    'No Response 2',
    'No Response 3',
    'Closed No Response',
    'Follow Up 1',
    'Follow Up 2',
    'Closed Follow Up',
    'Open - Not On Whatsapp',
    'Ops_Rejection - Document Pending',
    'Rejected - Existing loan with Swiggy',
    'Rejected - Inactive Rider',
    'Rejected - Entitled Score',
    'Rejected - No Data',
    'CA Rejected - Employer on payslips',
    'CA Rejected - Quess Deduction Issue',
    'Cold Lead_Eligibility Check',
    'Ops Rejected - Cooling Period',
    'Ops Rejected - Age Below 21',
    'Ops Rejected - Defaulter',
    'Ops Rejected - Bank Validation',
    'Ops Rejected - Low Salary',
    'Ops Rejected - Stipend Salary',
    'Ops Rejected - Rejected at NBFC',
    'Ops Rejected - Testing',
    'Ops_Rejected - eNach Not possible',
    // 'Ops_Rejected - Less than 3 Months Exp',
    'Ops_Rejected - Less Than Min Exp',
    'Ops_Rejected - Existing loan',
    'Insufficient Salaries',
    'Insufficient Employment Data',
    'Ops Rejected - High DPD',
    'Ops Rejected - Age Above 50',
    'CA Rejected - Less Than Min Exp',
]

export const OperationsStatusDataFetch: any = [
    'Pre Check Complete',
    'PRE_PROCESSING',
    'KYC Complete',
    'Digilocker KYC Incomplete',
    'Digilocker KYC Complete',
    'Ops Rejected',
    'Ops Documents Pending',
    'Ops Not Interested',
    'Cold Lead',
    'No Response',
    'No Response 2',
    'No Response 3',
    'Closed No Response',
    'Follow Up 1',
    'Follow Up 2',
    'Closed Follow Up',
    'Open - Not On Whatsapp',
    'Ops_Rejection - Document Pending',
    'CA_Query',
    'CA Rejected - eNACH Not Possible',
    // 'CA Rejected - Less Than 3 Months Exp',
    'CA Rejected - Less Than Min Exp',
    'CA Rejected - Salary Unavailable For 3 Months',
    'CA Rejected - No Salary Data',
    'CA Rejected - Old Salary Data',
    'CA Rejected - Employer on payslips',
    'CA_rejected - existing loan',
    'Rejected - Duplicate',
    'Rejected - Existing loan with Swiggy',
    'CA Rejected - Quess Deduction Issue',
    'Rejected - Inactive Rider',
    'Rejected - Entitled Score',
    'Rejected - No Data',
    'Cold Lead_Eligibility Check',
    'Ops Rejected - Cooling Period',
    'Ops Rejected - Age Below 21',
    'Ops Rejected - Defaulter',
    'Ops Rejected - Bank Validation',
    'Ops Rejected - Low Salary',
    'Ops Rejected - Stipend Salary',
    'Ops Rejected - Rejected at NBFC',
    'Ops Rejected - Testing',
    'Ops_Rejected - eNach Not possible',
    // 'Ops_Rejected - Less than 3 Months Exp',
    'Ops_Rejected - Less Than Min Exp',
    'Ops_Rejected - Existing loan',
    'Insufficient Salaries',
    'Insufficient Employment Data',
    'Ops Rejected - High DPD',
    'Ops Rejected - Age Above 50'
]

export const AppraisalStatus: any = [
    "PROCESSING",
    "PENDING_DISBURSAL",
    "COMPLETE",
    'Pending_at_NBFC',
    'Reprocessing Cases - Avanti',
    // 'CA_Query',
    'CA_Document Check Complete',
    'CA_Signature',
    'CA_Enach Sent',
    'CA_Agreement Sent',
    "CA_Sanction Letter Sent",
    'CA_Not Interested - eNach Pending',
    'CA_Not Interested - Agreement Pending',
    'CA Not Interested_Not Interested',
    'CA_Rejection - Low Entitled Score',
    'CA_Rejection - Applied from other ID',
    'CA_Rejection - Existing loan with us',
    'CA_Rejection - Employer on payslips',
    'CA_Rejection - Ref check failed',
    'CA_Rejection - Others',
    'CA_Rejection - No Data',
    'On Hold - Internal',
    'On Hold - NBFC' ,
    "CA_Rejected - CIBIL Score",
    "CA_Rejected - Deductions",
    "CA_Parking_1", 
    "CA_Parking_2", 
    "CA_Parking_3",
    "CA_Rejected Low Experience",
    "CA_Rejected Age Below 21",
    "CA_Rejected Low Salary",
    "CA_Rejected Total Quess O/S > 50percent",
    "CA_Cold Lead_Eligibility Check",
    "CA_Rejection - Penny Drop",
    "CA_Rejection - Dedupe"
]
export const AppraisalActualStatus: any = [
    "PROCESSING",
    "PENDING_DISBURSAL",
    "COMPLETE",
    "Reprocessing Cases - Avanti",
    'Pending_at_NBFC',
    'CA_Document Check Complete',
    'CA_Signature',
    'CA_Enach Sent',
    'CA_Agreement Sent',
    'CA_Sanction Letter Sent',
    'CA_Not Interested - eNach Pending',
    'CA_Not Interested - Agreement Pending',
    'CA Not Interested_Not Interested',
    'CA_Rejection - Low Entitled Score',
    'CA_Rejection - Applied from other ID',
    'CA_Rejection - Existing loan with us',
    'CA_Rejection - Employer on payslips',
    'CA_Rejection - Ref check failed',
    'CA_Rejection - Others',
    'CA_Rejection - No Data',
    'On Hold - Internal',
    'On Hold - NBFC',
    "CA_Rejected - CIBIL Score",
    "CA_Rejected - Deductions",
    "CA_Parking_1", 
    "CA_Parking_2", 
    "CA_Parking_3",
    "CA_Rejected Low Experience",
    "CA_Rejected Age Below 21",
    "CA_Rejected Low Salary",
    "CA_Rejected Total Quess O/S > 50percent",
    "CA_Cold Lead_Eligibility Check",
    "CA_Rejection - Penny Drop",
    "CA_Rejection - Dedupe"
]

export const enach_status = ['empty', 'pending', 'enach sent', 'source submitted', 'pending_bank_approval','mandate activated', 'mandate acknowledged', 'webhook_update_failed', 'not interested', 'NOT CREATED']

export const esign_status = ['empty', 'esignature sent', 'initiated', 'processed', 'uploaded', 'signed', 'failed', 'webhook_update_failed', 'not interested', 'ESign Expired', 'NOT CREATED']

export const esanction_status = ['empty', 'esignature sent', 'initiated', 'processed', 'uploaded', 'signed', 'failed', 'webhook_update_failed', 'not interested', 'ESign Expired', 'NOT CREATED']

export const nbfc_status = ['empty','NOT_CREATED', 'CIBIL_GENERATED', 'LEAD_API_FAILED', 'LEAD_GENERATED' ,'CIBIL_API_FAILED' ,
'LEAD_FETCH',
'LEAD_FETCH_FAILED',
'PREAPPROVED_DOC_UPLOAD',
'PREAPPROVED_DOC_UPLOAD_FAILED',
'POSTAPPROVED_DOC_UPLOAD',
'POSTAPPROVED_DOC_UPLOAD_FAILED',
'LOAN_CREATION',
'DISBURSED',
'REJECTED',
'CREDIT_APPROVED',
'CO_LENDER_APPROVAL_PENDING',
'LOAN_CREATION_FAILED',
'POST_STATUS_CHANGE',
'POST_STATUS_CHANGE_FAILED',
'PRE_STATUS_CHANGE',
'PRE_STATUS_CHANGE_FAILED',
'REPAYMENT_CREATION',
'REPAYMENT_CREATION_FAILED',
'REJECTED_CIBIL_LOW',
'REJECTED_AGE_LOW'
]

export const client_name = [
    'empty',
    "Swiggy",
    "Quess Corp Ltd",
    "Lets Transport",
    "The Maintenance Wallas",
    "Mobi Garage",
    "Pecopp",
    "wify",
    "common",
    "SIS",
    "Telangana Gig Workers",
    "myMobiForce",
    "Everest fleet",
    "Viridian",
    "Care24",
    "Rydo",
    "XYXX",
    "Smart staff",
    "zypp",
    "chefKart",
    "intrcity smartBus",
    "logipe",
    "chaloNetwork",
    "stana global",
    "moove",
    "b2c"
]
export const bank_status =["empty","NOT_CHECKED","VALID","INVALID","NOT_PRESENT"]
export const abbstatus = ['empty','UNKNOWN']

export const document_status = ['APPROVED','REJECTED'];

export const reference_status = ['APPROVED','REJECTED'];

export const notificationTemplates: any = [
    "Enach Pending",
    "Esign Pending",
    "Document Pending"
]
// enach(lotus pay)

// pending
// enach sent
// source submitted
// mandate activated
// mandate acknowledged

// esign (pichain)

// esignature sent
// initiated
// processed
// uploaded
// failed