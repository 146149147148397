import React, { useEffect, useState } from 'react';
import { Table, Card, Typography, Spin } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { MainReducer } from "../../types";
import { getPendingItems } from "../../utitlities/request";

const { Title } = Typography;

type TableData = {
    key: string;
    type: string;
    value: string | number;
};

const PendingItems: React.FC = () => {
    const [documentsData, setDocumentsData] = useState<TableData[]>([]);
    const [addressData, setAddressData] = useState<TableData[]>([]);
    const [referencesData, setReferencesData] = useState<TableData[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loan_request = useSelector((state: MainReducer) => {
        return state.loan.loanRequest.body;
    });
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const request_id = loan_request?.id;
            let response: any = {};
            if (request_id) response = await getPendingItems(request_id);


            const itemData = response?.data;
            if (itemData.message) {
                setDocumentsData([])
                setAddressData([])
                setReferencesData([])
            }
            else {
                const documents = [
                    { key: '1', type: 'Approved', value: itemData?.documents.approved.join(', ') },
                    { key: '2', type: 'Rejected', value: itemData?.documents.rejected.join(', ') },
                    { key: '3', type: 'Upload Pending', value: itemData?.documents.upload_pending.join(', ') },
                    { key: '4', type: "DigiLocker KYC", value: itemData?.digilocker_status || "Pending" }
                ];
                setDocumentsData(documents);

                const address = [
                    { key: '1', type: 'Current Address', value: itemData.address.current.present ? itemData.address.current.data : 'Not Present' },
                    { key: '2', type: 'Permanent Address', value: itemData.address.permanent.present ? itemData.address.permanent.data : 'Not Present' },
                ];
                setAddressData(address);

                const references = [
                    { key: '1', type: 'Pending Count', value: itemData.references.required },
                ];
                setReferencesData(references);
            }

        } catch (error) {
            console.error('Error fetching itemData:', error);
            setDocumentsData([])
            setAddressData([])
            setReferencesData([])
        }
        finally {
            setIsLoading(false);
        }

    };
    useEffect(() => {
        fetchData();
        return () => {
            setDocumentsData([])
            setAddressData([])
            setReferencesData([])
        };
    }, [loan_request]);

    const columns = [
        { title: 'Type', dataIndex: 'type', key: 'type' },
        { title: 'Value', dataIndex: 'value', key: 'value' },
    ];

    return (
        <div>
            <Card title="Pending Items" bordered={false} className="small-card">
                <Spin spinning={isLoading}>
                    <Title level={5} className="small-title">Documents</Title>
                    <Table
                        showHeader={false}
                        columns={columns}
                        dataSource={documentsData}
                        pagination={false}
                        bordered
                        rowKey="key"
                        size="small"
                        className="small-table"
                    />
                    <Title level={5} className="small-title">Address</Title>

                    <Table
                        showHeader={false}
                        columns={columns}
                        dataSource={addressData}
                        pagination={false}
                        bordered
                        rowKey="key"
                        size="small"
                        className="small-table"
                    />

                    <Title level={5} className="small-title">References</Title>
                    <Table
                        showHeader={false}
                        columns={columns}
                        dataSource={referencesData}
                        pagination={false}
                        bordered
                        rowKey="key"
                        size="small"
                        className="small-table"
                    />
                </Spin>
            </Card>

        </div>
    );
};

export default PendingItems;
